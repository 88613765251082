import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Antek_Website';

  constructor(
    public translate: TranslateService
  ) {
    // Aggiungi le lingue supportate
    translate.addLangs(['IT', 'EN']);

    // Imposta la lingua di default
    const defaultLang = 'IT';

    // Verifica se c'è una lingua salvata in localStorage
    const savedLang = localStorage.getItem('selectedLang');

    // Usa la lingua salvata o quella di default
    const langToUse = savedLang || defaultLang;

    translate.setDefaultLang(langToUse);
    translate.use(langToUse);
  }

  switchLang(lang: string) {
    this.translate.use(lang);

    // Salva la scelta dell'utente in localStorage
    localStorage.setItem('selectedLang', lang);
  }

  scrollToTop() {
    window.scrollTo(0, 0);
  }
}
