<div class="back-grey">
  <div class="container ">
    <div class="row c1">
      <div class="col-12 text-center">
        <div>
          <h4 [innerHTML]="'careers-title' | translate">
          </h4>
        </div>
        <div>
          <p class="fs-5" [innerHTML]="'careers-subtitle' | translate"></p>
        </div>
        <div>
          <button class="icon-size btn" (click)="scrollDown()">
            <fa-icon [icon]="faArrowDown"></fa-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="bg-white c1">
  <div class="container ">
    <div class="row">
      <div class="col-12 text-center">
        <div>
          <p class="fs-5" [innerHTML]="'careers-work1' | translate">
          </p>
        </div>
        <div class="mt-5">
          <h2>Open positions are:</h2>
        </div>
      </div>
    </div>
    <div class="row d-flex justify-content-center c1">
      <div class="col flex-center">
        <h1>FRONT-END DEVELOPER</h1>
        <p class="fs-5" [innerHTML]="'Front-end' | translate">
        </p>
      </div>
      <div class="col flex-center">
        <h1>BACK-END DEVELOPER</h1>
        <p class="fs-5" [innerHTML]="'Back-end' | translate">
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col text-center">
        <div [innerHTML]="'INFO-CONTATTI-CARRIERE' | translate">
        </div>
      </div>
    </div>
  </div>
</div>