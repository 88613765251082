import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-caro',
  templateUrl: './caro-home.component.html',
  styleUrls: ['./caro-home.component.scss']
})
export class CaroComponent {


}
