<div class="container c1">
  <div class="row">
    <div class="col-12 flex-center">
      <div id="carouselExampleDark" class="carousel carousel-dark slide">
        <div class="carousel-inner">
          <div class="carousel-item active" data-bs-interval="10000">
            <img class="img-carousel-contacts" src="assets/img/cadecaobibjmfjck.png" alt="...">
            <div class="mt-5 center-content w-1">
              <p [innerHTML]="'radio-deejay' | translate">
              </p>
            </div>
          </div>
          <div class="carousel-item" data-bs-interval="2000">
            <img class="img-carousel-contacts" src="assets/img/comoliferrari.jpeg" alt="...">
            <div class="mt-5 center-content w-1">
              <p [innerHTML]="'comoli-ferrari' | translate">
              </p>
            </div>
          </div>
          <div class="carousel-item">
            <img class="img-carousel-contacts" src="assets/img/ilcentro.jpeg" alt="...">
            <div class="mt-5 center-content w-1">
              <p [innerHTML]="'il-centro' | translate">
              </p>
            </div>
          </div>
          <div class="carousel-item">
            <img class="img-carousel-contacts" src="assets/img/abglhgpdnihadpli.png" alt="...">
            <div class="mt-5 center-content w-1">
              <p [innerHTML]="'aylook-caro-home' | translate">
              </p>
            </div>
          </div>

          <div class="carousel-item">
            <img class="img-carousel-contacts" src="assets/img/pbhlllgcbgpcacip.png" alt="...">
            <div class="mt-5 center-content w-1">
              <p [innerHTML]="'aylook-business-caro' | translate">
              </p>
            </div>
          </div>
          <div class="carousel-item">
            <img class="img-carousel-contacts" src="assets/img/mgcngledkgcmihnm.jpeg" alt="...">
            <div class="mt-5 center-content w-1">
              <p [innerHTML]="'apparecchi-aylook' | translate">
              </p>
            </div>
          </div>
          <div class="carousel-item">
            <img class="img-carousel-contacts" src="assets/img/mikeoabpbogjecjc.png" alt="...">
            <div class="mt-5 center-content w-1">
              <p [innerHTML]="'ABB' | translate">
              </p>
            </div>
          </div>
          <div class="carousel-item">
            <img class="img-carousel-contacts" src="assets/img/cblkdnjpgnjaiipp.png" alt="...">
            <div class="mt-5 center-content w-1">
              <p [innerHTML]="'Vimar' | translate">
              </p>
            </div>
          </div>
          <div class="carousel-item">
            <img class="img-carousel-contacts" src="assets/img/ihpcaahbjkhfgbil.png" alt="...">
            <div class="mt-5 center-content w-1">
              <p [innerHTML]="'elettrorapido-caro-home' | translate">
              </p>
            </div>
          </div>
        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  </div>
</div>