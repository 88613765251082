<div class="container c1">
  <div class="row">
    <div class="col-12">
      <h3 class="text-center margin-top p1">{{'First-title-home' | translate}}</h3>
    </div>
  </div>
</div>

<div class="p1 back-2 c1 center-content">
  <div class="content text-start">
    <h4 class="margin-top">
      <b>{{'DOMANDE' | translate}}</b>
    </h4>
    <p class="font-16" [innerHTML]="'DOMANDE-TESTO' | translate">
    </p>
  </div>
</div>

<div class="p1 back-3 m-5 c1 center-content">
  <div class="content text-start">
    <h4 class="margin-top">
      <b>{{'A PROPOSITO DI NOI' | translate}}</b>
    </h4>
    <p class="font-16" [innerHTML]="'A PROPOSITO DI NOI-TESTO' | translate">
    </p>
  </div>
</div>



<div class="container-fluid c2 back-2 flex-center ">
  <div class="row activity-container">
    <div class="col-12">
      <h4 class="text-center mb-5" [innerHTML]="'Rami-Attività' | translate"></h4>
      <div class="row">
        <div class="col-md-4 col-12">
          <div class="container-over">
            <img class="img-fluid image-1" src="assets/img/abilis.png" alt="Abilis">
            <div class="text-dark">
              <p class="fs-6" [innerHTML]="'Abilis' | translate">
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-12">
          <div class="container-over">
            <img class="img-fluid image-1" src="assets/img/aylook.png" alt="aylook">
            <div class="text-dark">
              <p class="fs-6" [innerHTML]="'Aylook' | translate">
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-12">
          <div class="container-over">
            <img class="img-fluid image-1" src="assets/img/elettrorapido.png" alt="elettrorapido">
            <div class="text-dark">
              <p class="fs-6" [innerHTML]="'Elettrorapido' | translate">
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="p1 back-3 c1 center-content">
  <div class="content text-start">
    <h4 class="margin-top">
      <b>
        {{'COSA FACCIAMO' | translate}}
      </b>
    </h4>
    <p class="font-16" [innerHTML]="'COSA FACCIAMO-TESTO' | translate">
    </p>
  </div>
</div>


<div class="p1 c1 back-2 center-content">
  <div class="content text-start">
    <h4 class="margin-top">
      <b>
        {{'IL NOSTRO METODO' | translate}}
      </b>
    </h4>
    <p class="font-16" [innerHTML]="'IL NOSTRO METODO-TESTO' | translate">
    </p>
  </div>
</div>


<div class="container-fluid back-3 c3 ">
  <div>
    <div class="row">
      <div class="col-12 text-center margin-top ">
        <h4 class="margin-bottom">
          <b>{{'PROGETTI FATTI CON' | translate}}</b>
        </h4>
        <br>
      </div>
    </div>
    <div>
      <app-caro></app-caro>
    </div>
  </div>
</div>

<div class="back-2">

  <div class="container c1 flex-center">
    <h3 class="m-5">
      <b>
        {{'USANO I NOSTRI PRODOTTI' | translate}}
      </b>
    </h3>
    <div class="row ">
      <div class="col-md-3 col-6">
        <img class="img-fluid image img-2" src="assets/img/enel.png" alt="enel">
      </div>
      <div class="col-md-3 col-6">
        <img class="img-fluid image img-2" src="assets/img/enac.png" alt="enac">
      </div>
      <div class="col-md-3 col-6">
        <img class="img-fluid image img-2" src="assets/img/fassa-bortolo.png" alt="fassa-bortolo">
      </div>
      <div class="col-md-3 col-6">
        <img class="img-fluid image img-2" src="assets/img/g-a.png" alt="g-a">
      </div>
    </div>
    <div class="row ">
      <div class="col-md-3 col-6">
        <img class="img-fluid image img-2" src="assets/img/g-cremonini.png" alt="g-cremonini">
      </div>
      <div class="col-md-3 col-6">
        <img class="img-fluid image img-2" src="assets/img/ministro-interno.png" alt="ministro-interno">
      </div>
      <div class="col-md-3 col-6">
        <img class="img-fluid image img-2" src="assets/img/rfi.png" alt="rfi">
      </div>
      <div class="col-md-3 col-6">
        <img class="img-fluid image img-2" src="assets/img/tim.png" alt="tim">
      </div>
    </div>
    <!--PER AGGIUNGERE UN'ALTRO SLOT BASTA CREARE UN'ALTRA RIGA(ROW) E USARE IL PATH GIA UTILIZZATO,MASSIMO 4 ITEM A RIGA(SOTTO VEDI ESEMPIO)-->
    <!--<div class="row ">
          <div class="col-md-3 col-6">
              <img class="img-fluid image img-2" src="assets/img/g-cremonini.png" alt="g-cremonini">
          </div>
          <div class="col-md-3 col-6">
              <img class="img-fluid image img-2" src="assets/img/g-cremonini.png" alt="g-cremonini">
          </div>
          <div class="col-md-3 col-6">
              <img class="img-fluid image img-2" src="assets/img/g-cremonini.png" alt="g-cremonini">
          </div>
          <div class="col-md-3 col-6">
              <img class="img-fluid image img-2" src="assets/img/g-cremonini.png" alt="g-cremonini">
          </div>
      </div>
      -->
  </div>
</div>