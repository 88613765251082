<div class="container">
  <div class="row c1">
    <div class="col-6 container">
      <h1>
        {{'ENTRIAMO IN CONTATTO' | translate}}
      </h1>
      <p class="" [innerHTML]="'ENTRIAMO IN CONTATTO-TESTO' | translate">
      </p>
    </div>
  </div>
  <div class="row">
    <div class="col-6 container">
      <p [innerHTML]="'INFO-CONTATTI' | translate">
      </p>
    </div>
  </div>
  <!-- <app-carousel-map></app-carousel-map> -->
</div>
<div class="container c1">
  <div class="row">
    <div class="col-12 flex-center">
      <div id="carouselExampleDark" class="carousel carousel-dark slide">
        <div class="carousel-inner">
          <div class="carousel-item active" data-bs-interval="10000">
            <img class="img-carousel-contacts" src="assets/img/mantova.jpg" alt="...">
            <div class="mt-5 text-center">
              <h1>{{'Mantova' | translate}}</h1>
              <p [innerHTML]="'Mantova-sub' | translate">
              </p>
            </div>
          </div>
          <div class="carousel-item" data-bs-interval="2000">
            <img class="img-carousel-contacts" src="assets/img/Chisinau.jpg" alt="...">
            <div class="mt-5 text-center">
              <h1>{{'Chisinau' | translate}}</h1>
              <p [innerHTML]="'Chisinau-sub' | translate">
              </p>
            </div>
          </div>
          <div class="carousel-item">
            <img class="img-carousel-contacts" src="assets/img/mosca.jpg" alt="...">
            <div class="mt-5 text-center">
              <h1>{{'Mosca' | translate}}</h1>
              <p [innerHTML]="'Mosca-sub' | translate">
              </p>
            </div>
          </div>
          <div class="carousel-item">
            <img class="img-carousel-contacts" src="assets/img/praga.jpg" alt="...">
            <div class="mt-5 text-center">
              <h1>{{'Praga' | translate}}</h1>
              <p [innerHTML]="'Praga-sub' | translate">
              </p>
            </div>
          </div>

          <div class="carousel-item">
            <img class="img-carousel-contacts" src="assets/img/zagabria.jpeg" alt="...">
            <div class="mt-5 text-center">
              <h1>{{'Zagabria' | translate}}</h1>
              <p [innerHTML]="'Zagabria-sub' | translate">
              </p>
            </div>
          </div>
          <div class="carousel-item">
            <img class="img-carousel-contacts" src="assets/img/bagnoregio.jpeg" alt="...">
            <div class="mt-5 text-center">
              <h1>{{'Bagnoregio' | translate}}</h1>
              <p [innerHTML]="'Bagnoregio-sub' | translate">
              </p>
            </div>
          </div>
          <div class="carousel-item">
            <img class="img-carousel-contacts" src="assets/img/la_valletta.jpeg" alt="...">
            <div class="mt-5 text-center">
              <h1>{{'Valletta' | translate}}</h1>
              <p [innerHTML]="'Valletta-sub' | translate">
              </p>
            </div>
          </div>
          <div class="carousel-item">
            <img class="img-carousel-contacts" src="assets/img/valmez.jpg" alt="...">
            <div class="mt-5 text-center">
              <h1>{{'Valmez' | translate}}</h1>
              <p [innerHTML]="'Valmez-sub' | translate">
              </p>
            </div>
          </div>
        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  </div>
</div>