import { Component } from '@angular/core';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';


@Component({
  selector: 'app-careers',
  templateUrl: './careers.component.html',
  styleUrls: ['./careers.component.scss']
})
export class CareersComponent {
  faArrowDown = faArrowDown;
  scrollDown() {
    window.scrollBy({
      top: 900, // la quantità di pixel da scrollare. Aumenta o diminuisci come preferisci.
      left: 0,
      behavior: 'smooth'  // animazione smooth, puoi rimuoverlo se non ti serve
    });
  }
}
