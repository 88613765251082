<div>
  <header class="mx-5 d-flex justify-content-center navbar-height">
    <div class="row d-flex align-items-start">
      <div class="col-4">
        <img class="img-fluid logo" src="assets/img/logo.png" alt="logo">
      </div>
      <div class="col-4">
        <nav class="navbar navbar-expand-lg">
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent2" aria-controls="navbarNav" aria-expanded="false"
            aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="container-fluid">
            <div class="collapse navbar-collapse" id="navbarSupportedContent2">
              <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                <li class="nav-item fs-5">
                  <a class="nav-link" aria-current="page" href="">{{'Home' | translate}}</a>
                </li>
                <li class="nav-item fs-5">
                  <a class="nav-link" href="/contacts">{{'Contatti' | translate}}</a>
                </li>
                <li class="nav-item fs-5">
                  <a class="nav-link" href="/careers">{{'Carriere' | translate}}</a>
                </li>
                <li class="nav-item fs-5 dropdown">
                  <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
                    aria-expanded="false">
                    {{'Prodotti' | translate}}
                  </a>
                  <ul class="dropdown-menu">
                    <li><a class="dropdown-item" href="https://www.aylook.com/">Aylook</a></li>
                    <li><a class="dropdown-item" href="https://abilis.net/">Abilis</a></li>
                    <li><a class="dropdown-item" href="https://www.elettrorapido.com/">ElettroRapido</a></li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
      <div class="col-4 d-flex justify-content-center">
        <nav class="navbar">
          <div class="container-fluid">
            <span class="form-inline">
              <select class="form-control" #selectedLang (change)="switchLang(selectedLang.value)">
                <option *ngFor="let language of translate.getLangs()" [value]="language"
                  [selected]="language === translate.currentLang">
                  {{ language }}
                </option>
              </select>
            </span>
          </div>
        </nav>
      </div>
    </div>
  </header>
  <router-outlet>
  </router-outlet>
</div>
<footer>
  <div class="row text-white mx-5">
    <div class="col-4 c1 d-flex justify-content-center">
      <div>
        <div>
          <img class="img-fluid logo-foot" src="assets/img/logo.png" alt="logo">
        </div>
        <div class="mt-4">
          <p>{{'First-title-home' | translate}}</p>
        </div>
      </div>
    </div>
    <div class="col-4 c1 d-flex justify-content-center ">
      <ul>
        <li>{{'Mantova' | translate}}</li>
        <li>{{'Chisinau' | translate}}</li>
        <li>{{'Mosca' | translate}}</li>
        <li>{{'Praga' | translate}}</li>
      </ul>
    </div>
    <div class="col-4 c1 d-flex justify-content-center">
      <ul>
        <li><a class="text-white" (click)="scrollToTop()" routerLink="">{{'Home' | translate}}</a></li>
        <li><a class="text-white" (click)="scrollToTop()" routerLink="contacts">{{'Contatti' | translate}}</a></li>
        <li><a class="text-white" (click)="scrollToTop()" routerLink="careers">{{'Carriere' | translate}}</a></li>
      </ul>
    </div>
  </div>
</footer>